<template>
  <TransitionRoot as="template" v-if="!loading" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-auto shadow-xl transform transition-all sm:my-8 sm:align-middle w-11/12 sm:max-h-screen9/10 sm:p-6">
            <!-- <div v-if="variant.attribs.list.length > 0">{{variant.attribs.list}}</div> -->
            <div>
                <h4 class="sr-only">Progres</h4>
                <p class="text-gray-500 text-xs font-medium uppercase tracking-wide">Progres edycji wariantu</p>
                <div class="mt-2" aria-hidden="true">
                <div class="bg-gray-200 rounded-full overflow-hidden">
                    <div class="h-2 transition-all duration-1000 bg-gradient-to-r from-blue-400 via-indigo-400 to-blue-600 rounded-full" :style="`width: ${progress}%`" />
                </div>
                <div class="hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-1">
                    <div class="text-blue-500">Podstawowe informacje</div>
                    <div class="text-center text-blue-500"></div>
                    <div class="text-center text-blue-500">Lokalizacja oraz stan magazynowy</div>
                    <div class="text-center text-blue-500">Koszty oraz atrybuty</div>
                    <div class="text-right text-blue-500" >Koniec</div>
                </div>
                </div>
            </div>

                <div v-show="step < 4" class="flex items-center justify-end mt-4">
                    <button type="button" @click.prevent="deleteAsk = true" class="transition ease-in-out duration-300 inline-flex items-center justify-center w-full sm:w-auto sm-w rounded border border-transparent bg-red-100 px-2.5 py-1.5 text-xs font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2">Usuń wariant</button>
                </div>

                <askForDelete v-if="deleteAsk" @accept="removeVariantFromProduct" :modalData="modalData" @closeModal="deleteAsk = false"></askForDelete>


            <form class="space-y-8 divide-y divide-gray-200 mt-4" v-if="!loading" v-show="step === 1">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Podstawowe informacje</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Nazwa wariantu<span class="text-red-500">*</span> </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="60" v-model="variant.name" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Symbol Subiekt GT </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.subiekt" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Bol.com ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.bol" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Hood.de ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.hood" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Wayfair.com ID </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="80" v-model="variant.integratorIds.wayfair" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="cover-photo" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Zdjęcie </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2" >
                                    <div class="group flex justify-center align-center rounded-md border-2 border-gray-300 overflow-hidden w-40 h-40 bg-white" v-show="imgPreviewSrc.length>0">
                                        <img :src="imgPreviewSrc" class="transition ease-in-out duration-300 object-center object-contain w-full">
                                    </div>
                                    <div v-show="imgPreviewSrc.length>0" class="mt-2 mb-2 flex items-center justify-center w-40">
                                        <button type="button" @click.prevent="clearImg" class="transition ease-in-out duration-300 inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Usuń zdjęcie</button>
                                    </div>
                                    
                                    
                                    <div class="transition ease-in-out duration-300 max-w-lg flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:opacity-70 cursor-pointer select-none" @click="triggerImgClick" v-show="imgPreviewSrc.length === 0" @drop.prevent="dropFile" @dragenter.prevent @dragover.prevent>
                                        <div class="space-y-1 text-center">
                                        <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                        <div class="flex items-center text-sm text-gray-600 pointer-events-none">
                                            <label for="file-upload" class="transition ease-in-out duration-300 relative cursor-pointer rounded-md font-medium text-blue-500 hover:text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500">
                                            <span>Wgraj zdjęcie</span>
                                            <input id="file-upload" name="file-upload" type="file" accept="image/*" class="sr-only" ref="mainImgUploader" @change="setMainImage" />
                                            </label>
                                            <p class="pl-1">lub przeciągnij je tutaj</p>
                                        </div>
                                        <p class="text-xs text-gray-500">.PNG, .JPG, .GIF, .SVG do 2 mb</p>
                                        <p class="text-xs text-gray-500">Preferowana wielkość: 160x160 px</p>
                                        </div>
                                    </div>
                                    <span @click.prevent="toggleOtherSourcePhoto" class="text-xs text-blue-500 select-none hover:text-blue-700 cursor-pointer">{{showImgInputField === true ? "Cofnij wpisywanie" : "Kliknij, aby podać link z innego źródła.."}}</span>
                                    <div v-if="showImgInputField" class="mt-2 w-full flex items-center">
                                        <div class="mt-1 border-b border-gray-300 focus-within:border-blue-600 w-1/2">
                                            <input type="text" v-model="imgExternalInputValue" class="block w-full border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0 sm:text-sm" placeholder="URL obrazka" />
                                        </div>
                                        <div class="ml-2" v-if="this.imgExternalInputValue.length > 0">
                                        <button type="button" @click.prevent="replaceImgWithExternalLink" class="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Zapisz</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Kategoria</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <select @change="setCategory" v-model="setOptions.category" class="border mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                                            <option value="">Brak</option>
                                            <option v-for="(category,categoryIndex) in options.category" :value="categoryIndex" :key="categoryIndex">{{category.name}} - (ID: {{category.id}})</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Producent</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">

                                    <div v-if="!variant.selfProduction" class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" maxlength="40" v-model="variant.manufacturer.name" :disabled="variant.selfProduction" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <div v-if="variant.selfProduction" class="pt-1.5 pb-2">
                                        <span class="py-2 sm:text-sm">{{typeof variant.manufacturer === 'string' ? variant.manufacturer : variant.manufacturer.name}}</span>
                                    </div>
                                    
                                    <fieldset class="mt-2">
                                        <legend class="sr-only">Produkcja własna</legend>
                                        <div class="relative flex items-start">
                                            <div class="flex h-5 items-center">
                                                <input id="selfProduction" @change="changeManufacturer()" v-model="variant.selfProduction" aria-describedby="comments-description" name="comments" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500 cursor-pointer" />
                                            </div>
                                            <div class="ml-2 text-sm">
                                                <label for="selfProduction" class="text-gray-700 cursor-pointer select-none">Produkcja własna</label>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Model</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="40" v-model="variant.model" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Wymiary</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Długość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm opacity-40 pointer-events-none select-none">
                                                <input disabled type="number" @change="checkLength('single')" name="dimension" v-model="variant.dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm opacity-40 pointer-events-none select-none">
                                                <input disabled type="number" @change="checkHeight('single')" name="dimension" v-model="variant.dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-1 sm:mt-0">
                                            <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm opacity-40 pointer-events-none select-none">
                                                <input disabled type="number" @change="checkWidth('single')" name="dimension" v-model="variant.dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500 italic">Wymiar wylicza się automatycznie, podczas zmiany wymiarów paczek.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Waga</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm opacity-40 pointer-events-none">
                                                <input disabled type="number" @change="checkWeight('single')" name="weight" v-model="variant.weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                                                <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                    <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500 italic">Waga wylicza się automatycznie, podczas zmiany wagi paczek.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość paczek</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                        <div class="mt-1 sm:mt-0">
                                            <div>
                                                <div class="max-w-lg relative rounded-md shadow-sm">
                                                <input type="number" @change="fixAmountOfPackages()" name="amountOfPackages" v-model="variant.amountOfPackages" id="amountOfPackages" class="flex-1 block w-full py-2 px-4 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość paczek" aria-describedby="amountOfPackages" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
               
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">EAN</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.ean" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> SKU</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.sku" maxlength="14" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Opis wariantu </label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <textarea v-model="variant.description" rows="3" class="max-w-lg py-2 px-4 shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md" />
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 1</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.extraField1" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 2</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" v-model="variant.extraField2" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500" >Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"> Pole dodatkowe 3</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input type="text" v-model="variant.extraField3" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500">Pole dodatkowe, bez przeznaczenia.</p>
                                </div>
                            </div>

                        </div>
                    </div>            


                </div>

                <div class="pt-5">
                    <div class="flex flex-col-reverse sm:flex-row justify-end">
                        <button type="button" @click.prevent="this.emitClose()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                        <button type="submit" @click.prevent="changeStep(2)" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
                    </div>
                </div>
            </form>


            <form class="space-y-8 divide-y divide-gray-200 mt-6" v-show="step === 2">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Informacje o lokalizacji</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Położenie</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="40" v-model="variant.storage.location" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>
                       

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Dodatkowe informacje</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input maxlength="65" v-model="variant.storage.additionalInfo" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="mt-10">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Informacje o paczkach</p>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-1">Identyfikacja paczek</label>

                                <div class="sm:mt-0 sm:col-span-2">
                                <span class="text-gray-500 text-xs pl-1">Nazwa</span>
                                    <div v-for="(part, index) in variant.amountOfPackages" :key="index">
                                        <div :class="['flex flex-row items-center gap-2 mb-3']">
                                            <div class="w-full max-w-lg flex rounded-md shadow-sm flex-row">
                                                <span class="inline-flex w-8 items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm">{{part}}</span>
                                                <input @keypress="blockSpecialCharacters($event)" type="text" v-model="variant.parts[index+1].name" name="part-name" id="part-name" class="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" :placeholder="`XXXXX-${part}`" />
                                            </div>
                                            <ChevronDownIcon @click.prevent="openAdditionalInformation(index+1)" :class="[toggleInformation[index+1].open ? 'rotate-180' : '', 'h-6 w-6 transform text-gray-400 transition-all duration-300 cursor-pointer hover:text-gray-600']" />
                                        </div>
                                        <Transition name="fade">
                                        <div v-if="toggleInformation[index+1].open" class="mt-4 mb-6">
                                           <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                                <div class="relative col-span-3 mb-2">
                                                    <div class="absolute inset-0 flex items-center" aria-hidden="true">
                                                        <div class="w-full border-t border-gray-300" />
                                                    </div>
                                                    <div class="relative flex justify-center">
                                                        <span class="bg-white px-2 text-sm text-gray-500 uppercase font-semibold">Specyfikacja</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1 sm:mt-0">
                                                <div class="sm:grid sm:grid-cols-3 max-w-lg sm:gap-4 sm:items-start">
                                                    <div class="mt-1 sm:mt-0">
                                                        <span class="text-gray-500 text-xs pl-1">Długość</span>
                                                        <div>
                                                            <div class="max-w-lg relative rounded-md shadow-sm">
                                                            <input type="number" @change="checkLength('multiple', index+1)" name="dimension" v-model="variant.parts[index+1].dimensions.length" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Długość" aria-describedby="length-unit" />
                                                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-1 sm:mt-0">
                                                        <span class="text-gray-500 text-xs pl-1">Wysokość</span>
                                                        <div>
                                                            <div class="max-w-lg relative rounded-md shadow-sm">
                                                            <input type="number" @change="checkHeight('multiple', index+1)" name="dimension" v-model="variant.parts[index+1].dimensions.height" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Wysokość" aria-describedby="height-unit" />
                                                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="mt-1 sm:mt-0">
                                                        <span class="text-gray-500 text-xs pl-1">Szerokość</span>
                                                        <div>
                                                            <div class="max-w-lg relative rounded-md shadow-sm">
                                                            <input type="number" @change="checkWidth('multiple', index+1)" name="dimension" v-model="variant.parts[index+1].dimensions.width" id="dimension" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Szerokość" aria-describedby="width-unit" />
                                                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <span class="text-gray-500 sm:text-sm" id="price-currency"> cm </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-1 sm:mt-0">
                                                <span class="text-gray-500 text-xs pl-1">Waga</span>
                                                <div class="sm:grid sm:grid-cols-1 max-w-lg sm:gap-4 sm:items-start">
                                                    <div class="mt-1 sm:mt-0">
                                                        <div>
                                                            <div class="max-w-lg relative rounded-md shadow-sm">
                                                            <input type="number" @change="checkWeight('multiple', index+1)" name="weight" v-model="variant.parts[index+1].weight.amount" id="weight" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Waga" aria-describedby="weight-unit" />
                                                            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                                                <span class="text-gray-500 sm:text-sm" id="price-currency"> kg </span>
                                                            </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </Transition>
                                        <Transition name="fade">
                                        <span class="text-red-500 select-none text-xs font-semibold" v-if="errors.parts[index+1].error">Brak podanej nazwy dla paczki.</span>
                                        </Transition>
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500 italic">Nazwy paczek w systemie muszą być unikalne, dla właściwego zarządzania nimi w magayznie. </p>
                                </div>

                            </div>
                        </div>

                        <div class="mt-12">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Stan magazynowy</p>
                        </div>

                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Ilość</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div>
                                        <div class="mt-1 max-w-lg relative rounded-md shadow-sm">
                                        <input type="number" @change="fixQuantity()" v-model="variant.quantity" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-12 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" placeholder="Ilość produktów w magazynie" aria-describedby="variant-quantity" />
                                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> SZT </span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-12">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Zewnętrzne źródła</p>
                        </div>

                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">

                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Link</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="max-w-lg flex rounded-md shadow-sm">
                                        <input v-model="variant.link.href" type="text" class="flex-1 block w-full py-2 px-4 border focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md sm:text-sm border-gray-300" />
                                    </div>
                                    <p class="mt-2 text-xs text-gray-500">Uzupełnienie tego pola umożliwi szybki dostęp do tego wariantu. </p>
                                    <p class="mt-2 text-xs text-gray-500 italic">Przykładowo: </p>
                                    <p class="text-xs text-gray-500 italic">https://jan-art.sklep.pl/produkt?id=12345</p>
                                    
                                </div>
                            </div>



                        </div>


                    </div>            
                </div>

                <div class="pt-5">
                <div class="flex flex-col-reverse sm:flex-row justify-end">
                    <button type="button" @click.prevent="this.emitClose()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                    <button type="button" @click.prevent="changeStep(1)" class="transition ease-in-out duration-300 sm:ml-3 mt-2 sm:mt-0 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                    <button type="submit" @click.prevent="validateParts" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Następny krok</button>
                </div>
                </div>
            </form>


            <form class="space-y-8 divide-y divide-gray-200 mt-4" v-show="step === 3">
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div>
                        <div>
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Koszty</p>
                        </div>
                        <div class="mt-6 sm:mt-2 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Koszty produkcji</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkProductionCost" v-model="variant.pricesAndCosts.productionCost" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Production Cost" aria-describedby="price-currency" />
                                        <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> {{ variant.pricesAndCosts.currency}}</span>
                                        </div> -->
                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Sugerowana cena końcowa</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkSellPrice" name="price" v-model="variant.pricesAndCosts.sellPrice" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Sell Price" aria-describedby="price-currency" />
                                        <!-- <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                            <span class="text-gray-500 sm:text-sm" id="price-currency"> PLN </span>
                                        </div> -->

                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Koszt reklamacji</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <div class="mt-1 w-full relative rounded-md shadow-sm">
                                        <input type="number" @change="checkComplaintCost" name="price" id="price" class="flex-1 block w-full py-2 pl-4 pr-4 border border-r-0 focus:ring-blue-500 focus:border-blue-500 min-w-0 rounded-md rounded-r-none sm:text-sm border-gray-300" placeholder="Complaint Cost" v-model="variant.pricesAndCosts.complaintCost" aria-describedby="price-currency" />
                                        </div>
                                        <select v-model="variant.pricesAndCosts.currency" class="border border-l-0 block mt-1 pl-3 pr-10 py-2 text-gray-500 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md rounded-l-none">
                                                <option value="PLN">PLN</option>
                                                <option value="EUR">EUR</option>
                                                <option value="GBP">GBP</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                                <label class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">Stawka VAT</label>
                                <div class="mt-1 sm:mt-0 sm:col-span-2">
                                    <div class="flex max-w-lg">
                                        <select v-model="variant.pricesAndCosts.taxRate" class="border w-full block mt-1 pl-3 pr-10 py-2 sm:text-sm border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md">
                                                <option :value="0">0%</option>
                                                <option v-for="index in 30" :key="index" :value="parseFloat(index)">{{index}}%</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                </div>
                <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                    <div class="mt-12">
                        <div :class="['flex justify-between items-center', variant.attribs.list.length>0 ? 'border-b border-gray-200 pb-4' : '']">
                            <p class="text-gray-500 text-sm font-medium uppercase tracking-wide">Atrybuty</p>
                            <button @click.prevent="showModal = true" type="button" class="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                <PlusSmIconOutline class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                        <div class="mt-6">
                            <ul role="list" class="-my-5 divide-y divide-gray-200" v-if="variant.attribs.list.length>0">
                                <li v-for="(attrib,atrbIndx) in variant.attribs.list" :key="attrib.id" :class="[atrbIndx < variant.attribs.list.length -1 ? 'py-4' : 'pt-4 pb-2']">
                                    <div class="flex items-center space-x-4">
                                        <div class="flex-shrink-0">
                                            <!-- <img class="h-10 w-10 rounded-full" :src="person.imageUrl" alt="" /> -->
                                            <div class="h-10 w-10 rounded-full text-white bg-green-400 flex justify-center items-center text-sm"> {{attrib.parent.name.substring(0,3).toUpperCase()}}</div>
                                        </div>
                                        <div class="flex-1 min-w-0">
                                            <p class="text-sm font-medium text-gray-900 truncate">
                                                <router-link :to="`/dashboard/products/attributes/modify?attrib=${attrib.parent.id}`" target="_blank" class="text-xs opacity-50 hover:text-blue-500">({{attrib.parent.name}})</router-link> {{ attrib.name }} 
                                            </p>
                                            <p class="text-sm text-gray-500 truncate">
                                                ID: {{ attrib.id }}
                                            </p>
                                        </div>
                                        <div>
                                            <button class="transition ease-in-out duration-300 inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-red-50 hover:text-red-500 hover:border-red-200" @click.prevent="variant.attribs.list.splice(atrbIndx,1)"> Usuń </button>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <EmptyState v-if="variant.attribs.list.length === 0"></EmptyState>
                        </div>


                    </div>
            
                </div>

                <div class="pt-5">
                    <div class="flex flex-col-reverse sm:flex-row justify-end">
                        <button type="button" @click.prevent="this.emitClose()" class="transition ease-in-out duration-300 mt-2 sm:mt-0 bg-red-500 py-2 px-4 border border-red-600 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">Porzuć</button>
                        <button type="button" @click.prevent="changeStep(2)" class="transition ease-in-out duration-300 mt-2 sm:mt-0 sm:ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">Poprzedni krok</button>
                        <button type="submit" @click.prevent="changeStep(4)" :class="{'transition ease-in-out duration-300 sm:ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ': true, 'bg-blue-300': !validation1, 'bg-blue-600 hover:bg-blue-700 focus:ring-blue-500': validation1}">Edytuj wariant</button>
                    <!-- @click.prevent="changeStep(4)" -->
                    </div>
                </div>
            </form>
            <AttribListModal :show="showModal" @close="closeModal" @addSelectedOptions="pushToAttribList"></AttribListModal>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import {db} from "@/firebase/gfbconf.js";
import axios from 'axios';
import moment from 'moment';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, PlusCircleIcon, ChevronDownIcon, DotsHorizontalIcon, HomeIcon, TrashIcon, DotsVerticalIcon } from '@heroicons/vue/outline'
import { PlusSmIcon as PlusSmIconOutline, CheckIcon } from '@heroicons/vue/outline'
import EmptyState from '../components/EmptyState.vue';
import askForDelete from '@/components/AlertModal.vue';
import AttribListModal from '@/components/AttribListModal.vue';
import LoadingSpinnerHub from './LoadingSpinnerHub.vue';

export default {
    data() {
        return {
            open: true,
            toggleInformation: {},
            selected: [],
            dbListener: null,
            imgExternalInputValue: "",
            showImgInputField: false,
            saveImage: "",
            newMainImage: null,
            deleteAsk: false,
            loading: true,
            fileLimitSize: 2097152,
            showModal: false,
            setOptions: {
                category: "",
            },
            photoToDeletePath: "",
            imgPreviewSrc: "",
            progress: 0,
            step: 1,
            options: {
                category: [],
            },
            errors: {
                parts: {},
            },
            modalData: {
                    headerText: "Usuwanie produktu",
                    bodyText: "Czy na pewno chcesz usunąć ten wariant? Po aktualizacji produktu jego dane zostaną permamentnie usunięte.",
                    acceptButtonText: "Usuń"
                },
            isCutted: {},
            variant: {
                name: "",
                model: "",
                active: true,
                quantity: 0,
                id: "",
                integratorIds: {
                    bol: "",
                    hood: "",
                    wayfair: "",
                },
                meta: {
                    createdBy: null,
                    createdDate: null,
                    lastModificationDate: null,
                    listOfChanges: []
                },
                parts: {},
                ean: "",
                sku: "",
                image: {
                    url: "",
                    ref: null
                },
                amountOfPackages: 1,
                manufacturer: {
                    name: '',
                },
                selfProduction: false,
                category: {
                    name: "",
                    ref: null,
                    id: "",
                },
                inStorage: false,
                extraField1: "",
                extraField2: "",
                extraField3: "",
                description: "",
                pricesAndCosts: {
                    currency: "PLN",
                    sellPrice: 0,
                    complaintCost: 0,
                    productionCost: 0
                },
                dimensions: {
                        unit: "cm",
                        height: 0,
                        width: 0,
                        length: 0
                    },
                weight: {
                        unit: "kg",
                        amount: 0,
                    },
                storage: {
                    location: "",
                    additionalInfo: ""
                },
                link: {
                    href: ""
                },
                attribs: {
                    list: []
                }
            },
            segments: [],
            locations: [],
            loading: false,
            productCopy: null,
            toggleInformation: {},
        }
    },
  components: {
    LoadingSpinnerHub, DotsVerticalIcon, Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions, Dialog, ChevronDownIcon, DialogOverlay, DialogTitle, TransitionChild, DotsHorizontalIcon, Menu, MenuButton, MenuItem, MenuItems, TransitionRoot, ExclamationIcon, PlusSmIconOutline, AttribListModal, EmptyState, askForDelete, PlusCircleIcon, CheckIcon, HomeIcon, TrashIcon
  },
  props: ['product', 'optionsMain'],
  methods: {
    blockSpecialCharacters(event) {
        var regex = new RegExp("^[a-zA-Z0-9-]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    },
    openAdditionalInformation(indx)
    {
        if(this.toggleInformation[indx].open)
        {
            this.toggleInformation[indx].open = false
        }
        else
        {
            for (let i = 0; i < Object.keys(this.toggleInformation).length; i++) {
                this.toggleInformation[i+1].open = false
            }
            this.toggleInformation[indx].open = true
        }
    },
    replaceImgWithExternalLink()
    {
        if(this.imgExternalInputValue.length === 0) return;
        this.clearImg();
        this.variant.image.ref = null;
        this.variant.image.url = this.imgExternalInputValue;
        this.imgPreviewSrc = this.imgExternalInputValue;
        this.imgExternalInputValue = "";
        this.showImgInputField = false;
    },
    toggleOtherSourcePhoto()
    {
        this.clearImg();
        this.variant.image.ref = null;
        this.variant.image.url = "";
        this.showImgInputField = !this.showImgInputField;
    },
    fixAmountOfPackages(){
        this.variant.amountOfPackages = Math.ceil(this.variant.amountOfPackages)
        if(this.variant.amountOfPackages < 1){
            this.variant.amountOfPackages = 1
        }
        if(this.variant.amountOfPackages > 100){
            this.variant.amountOfPackages = 100
        }
        this.variant.amountOfPackages = parseInt(this.variant.amountOfPackages)
    },
    updateVariant(){
        this.variant.pricesAndCosts.complaintCost = parseFloat(this.variant.pricesAndCosts.complaintCost)
        this.variant.pricesAndCosts.productionCost = parseFloat(this.variant.pricesAndCosts.productionCost)
        this.variant.pricesAndCosts.sellPrice = parseFloat(this.variant.pricesAndCosts.sellPrice)

        let total_height = 0;
        let total_width = 0;
        let total_length = 0;
        let total_weight = 0;
        for(let i=0; i<this.variant.amountOfPackages; i++)
        {
            total_height += parseFloat(this.variant.parts[i+1].dimensions.height);
            total_width += parseFloat(this.variant.parts[i+1].dimensions.width);
            total_length += parseFloat(this.variant.parts[i+1].dimensions.length);
            total_weight += parseFloat(this.variant.parts[i+1].weight.amount);
        }

        this.variant.dimensions.height = parseFloat(total_height)
        this.variant.dimensions.width = parseFloat(total_width)
        this.variant.dimensions.length = parseFloat(total_length)
        this.variant.weight.amount = parseFloat(total_weight)
        this.variant.amountOfPackages = parseInt(this.variant.amountOfPackages);
        this.variant.meta.lastModificationDate = moment().toDate();

        const parts = Object.entries(this.variant.parts)
        if(this.productCopy.amountOfPackages > this.variant.amountOfPackages)
        {
            const cutted = parts.splice(0, this.variant.amountOfPackages)
            for(let i=0; i<cutted.length; i++)
            {
                if(this.isCutted[i] != null && this.isCutted[i].cut)
                {
                    cutted[i][1].name = cutted[i][1].name+`-${i+1}`;
                }
                else
                {
                    cutted[i][1].name = cutted[i][1].name;
                }
                cutted[i][1].dimensions.height = parseFloat(cutted[i][1].dimensions.height)
                cutted[i][1].dimensions.width = parseFloat(cutted[i][1].dimensions.width)
                cutted[i][1].dimensions.height = parseFloat(cutted[i][1].dimensions.height)
                cutted[i][1].weight.amount = parseFloat(cutted[i][1].weight.amount)
            }
            this.variant.parts = Object.fromEntries(cutted);
        }
        else
        {
            for(let i=0; i<parts.length; i++)
            {
                if(this.isCutted[i] != null && this.isCutted[i].cut)
                {
                    parts[i][1].name = parts[i][1].name+`-${i+1}`;
                }
                else
                {
                    parts[i][1].name = parts[i][1].name;
                }
                parts[i][1].dimensions.height = parseFloat(parts[i][1].dimensions.height)
                parts[i][1].dimensions.width = parseFloat(parts[i][1].dimensions.width)
                parts[i][1].dimensions.length = parseFloat(parts[i][1].dimensions.length)
                parts[i][1].weight.amount = parseFloat(parts[i][1].weight.amount)
            }
            this.variant.parts = Object.fromEntries(parts);
        };
        
        for (let part in this.variant.parts) {
            this.variant.parts[part].name = this.variant.parts[part].name.replace(/[^\w-]+/g, '');
        }

        if(this.imgPreviewSrc.length > 0){
            if(this.$refs.mainImgUploader.files.length > 0){
                this.variant.imageToUpload = this.$refs.mainImgUploader.files[0]
            }else{
                this.variant.imageToUpload = this.saveImage
            }
            this.variant.imagePreview = this.imgPreviewSrc
        }
        if(this.variant.imageToUpload !== undefined){
            if(this.variant.imageToUpload.length < 1){
                delete this.variant.imageToUpload
                delete this.variant.imagePreview
            }
        }
        if(this.photoToDeletePath !== null){
            if(this.photoToDeletePath.length > 0){
                this.variant.imageToDelete = this.photoToDeletePath
            }
        }
        
        this.setProductStorageSegment()
        this.$emit("editVariant", this.variant)
    },
    removeVariantFromProduct(){
        this.$emit("removeVariant", this.variant.id);
        this.emitClose()
    },
    fixQuantity(){
        if(this.variant.quantity < 0){
            this.variant.quantity = 0
        }
        if(this.variant.quantity > 999){
            this.variant.quantity = 999
        }
    },
    changeManufacturer(){
        if(this.variant.selfProduction)
        {
            this.variant.manufacturer.name = "Jan-Art Sp. z o.o."
        }
        else
        {
            if(typeof this.product.manufacturer === 'string')
            {
                if(this.product.manufacturer.length > 0)
                {
                    this.variant.manufacturer.name = this.product.manufacturer
                }
                else
                {
                    this.variant.manufacturer = '';
                }
            }
            else
            {
                if(this.product.manufacturer.name.length > 0)
                {
                    this.variant.manufacturer.name = this.product.manufacturer.name
                }
                else
                {
                    this.variant.manufacturer = '';
                }
            }
        }
    },
    emitClose()
    {
        this.$emit("closeModal");
    },
    emitAccept()
    {
        this.$emit("accept");
        this.emitClose()
    },
    getCopyOfProduct()
    {
        let productCopyData = {
            active: this.product.active,
            name: this.product.name,
            model: this.product.model,
            manufacturer: {
                name: typeof this.product.manufacturer === 'string' ? this.product.manufacturer : this.product.manufacturer.name,
            },
            description: this.product.description,
            selfProduction: this.product.selfProduction,
            amountOfPackages: this.product.amountOfPackages,
            integratorIds: {
                bol: this.product.integratorIds.bol,
                hood: this.product.integratorIds.hood,
                wayfair: this.product.integratorIds.wayfair,
            },
            attribs: {
                list: []
            },
            category: {
                id: this.product.category.id,
                name: this.product.category.name,
                ref: this.product.category.id.length > 0 ? db.collection("Categories").doc(this.product.category.id) : null,
            },
            dimensions: {
                unit: this.product.dimensions.unit,
                height: this.product.dimensions.height,
                width: this.product.dimensions.width,
                length: this.product.dimensions.length,
            },
            weight: {
                unit: this.product.weight.unit,
                amount: this.product.weight.amount
            },
            storage: {
                additionalInfo: this.product.storage.additionalInfo,
                location: this.product.storage.location
            },
            ean: this.product.ean,
            extraField1: this.product.extraField1,
            extraField2: this.product.extraField2,
            extraField3: this.product.extraField3,
            id: this.product.id,
            image: {
                url: this.product.image.url,
                ref: this.product.image.ref
            },
            link: {
                href: this.product.link.href
            },
            meta: {
                createdBy: this.product.meta.createdBy,
                createdDate: this.product.meta.createdDate.seconds !== undefined ? moment.unix(this.product.meta.createdDate.seconds).toDate() : this.product.meta.createdDate,
                lastModificationDate: this.product.meta.lastModificationDate.secondss !== undefined ? moment.unix(this.product.meta.lastModificationDate.seconds).toDate() : this.product.meta.lastModificationDate,
                listOfChanges: [],
                userId: this.product.meta.userId
            },
            pricesAndCosts: {
                complaintCost: this.product.pricesAndCosts.complaintCost,
                currency: this.product.pricesAndCosts.currency,
                productionCost: this.product.pricesAndCosts.productionCost,
                sellPrice: this.product.pricesAndCosts.sellPrice,
                taxRate: this.product.pricesAndCosts.taxRate
            },
            quantity: this.product.quantity,
            sku: this.product.sku,
            mainImgUploader: this.product.imageToUpload !== undefined ? this.product.imageToUpload : "",
            imagePreview: this.product.imagePreview !== undefined ? this.product.imagePreview : "",
            }
            for(let i = 0; i < this.product.attribs.list.length; i++){
                let current = this.product.attribs.list[i]
                let preparedAttrib = {
                    id: current.id,
                    meta: current.meta,
                    name: current.name,
                    ref: db.collection('Attributes').doc(current.id),
                    parent: {
                        id: current.parent.id,
                        name: current.parent.name,
                        ref: db.collection('GroupsOfAttributes').doc(current.parent.id),
                        type: current.parent.type,
                    }
                }
                productCopyData.attribs.list.push(preparedAttrib)
            }
            for(let i = 0; i < this.product.meta.listOfChanges.length; i++){
                productCopyData.meta.listOfChanges.push(this.product.meta.listOfChanges[i])
            }
            if(this.product.parts != null)
            {
                if(Object.keys(this.product.parts).length > 0)
                {
                    productCopyData.parts = this.product.parts
                }
            }
            if(this.product.baselinkerId !== undefined)
            {
                productCopyData.baselinkerId = this.product.baselinkerId;
            }
            if(this.product.apiloId !== undefined)
            {
                productCopyData.apiloId = this.product.apiloId;
            }
            if(this.product.subiekt !== undefined)
            {
                productCopyData.subiekt = this.product.subiekt;
            }

            // if(this.product.imageToUpload !== undefined && this.product.imagePreview !== undefined){
            //     this.$refs.mainImgUploader.files[0] = this.product.imageToUpload
            //     this.imgPreviewSrc = this.product.imagePreview
            // }
        return productCopyData;
    },
    checkForProduct(){
        if(this.product === null){
            this.checkForProduct()
        }else{
            let product = this.getCopyOfProduct();
            this.productCopy = this.getCopyOfProduct();
            this.variant.active = product.active
            this.variant.attribs = product.attribs
            this.variant.category = product.category
            this.variant.integratorIds = product.integratorIds
            this.variant.description = product.description
            this.variant.manufacturer.name = typeof product.manufacturer === 'string' ? product.manufacturer : product.manufacturer.name
            this.variant.selfProduction = product.selfProduction
            this.variant.dimensions = product.dimensions
            this.variant.model = product.model
            this.variant.name = product.name
            this.variant.storage = product.storage
            this.variant.ean = product.ean
            this.variant.extraField1 = product.extraField1
            this.variant.extraField2 = product.extraField2
            this.variant.extraField3 = product.extraField3
            this.variant.id = product.id
            this.variant.image = product.image
            this.variant.link = product.link
            this.variant.meta = product.meta
            this.variant.pricesAndCosts = product.pricesAndCosts
            this.variant.quantity = product.quantity
            this.variant.sku = product.sku
            this.variant.amountOfPackages = product.amountOfPackages
            this.variant.weight = product.weight;

            if(product.parts == null)
            {
                for(let i=0; i<this.variant.amountOfPackages; i++)
                {
                    if(this.variant.parts[i+1] === undefined)
                    {
                        this.variant.parts[i+1] = {
                            name: '',
                            quantity: 0,
                            dimensions: {
                                unit: "cm",
                                height: 0,
                                width: 0,
                                length: 0
                            },
                            weight: {
                                unit: "kg",
                                amount: 0,
                            },
                        };
                    }
                    if(this.errors.parts[i+1] === undefined)
                    {
                        this.errors.parts[i+1] = {
                            error: false,
                        };
                    }
                    this.toggleInformation[i+1] = {
                        open: false
                    }
                } 
            }
            else
            {
                const oldParts = JSON.parse(JSON.stringify(product.parts))
                const parts = Object.entries(oldParts);
                for(let i=0; i<parts.length; i++)
                {
                    let str = parts[i][1].name;

                    if(str.charAt(0) === '-')
                    {
                        parts[i][1].name = str;
                        this.isCutted[i] = {
                            cut: false
                        }
                    }
                    else
                    {
                        let n = str.lastIndexOf('-');
                        if(n > -1)
                        {
                            this.isCutted[i] = {
                                cut: true
                            };
                            let result = str.slice(0, n);
                            parts[i][1].name = result;
                        }
                        else
                        {
                            this.isCutted[i] = {
                                cut: false
                            }
                        }
                    }

                    parts[i][1].dimensions = {
                        unit: "cm",
                        height: parts[i][1].dimensions == null ? 0 : parts[i][1].dimensions.height == null ? 0 : parts[i][1].dimensions.height,
                        width: parts[i][1].dimensions == null ? 0 : parts[i][1].dimensions.width == null ? 0 : parts[i][1].dimensions.width,
                        length: parts[i][1].dimensions == null ? 0 : parts[i][1].dimensions.length == null ? 0 : parts[i][1].dimensions.length
                    },
                    parts[i][1].weight = {
                        unit: "kg",
                        amount: parts[i][1].weight == null ? 0 : parts[i][1].weight.amount == null ? 0 : parts[i][1].weight.amount,
                    },
                    this.toggleInformation[i+1] = {
                        open: false
                    }
                    if(this.errors.parts[i+1] === undefined)
                    {
                        this.errors.parts[i+1] = {
                            error: false,
                        };
                    }
                }
                const clearParts = Object.fromEntries(parts)
                this.variant.parts = clearParts;
            }

            if(product.baselinkerId !==undefined)
            {
                this.variant.baselinkerId = product.baselinkerId;
            }
            if(product.apiloId !== undefined)
            {
                this.variant.apiloId = product.apiloId;
            }
            if(product.subiekt !==undefined)
            {
                this.variant.subiekt = product.subiekt;
            }
            if(this.product.imageToUpload !== undefined && this.product.imagePreview !== undefined){
                this.imgPreviewSrc = product.imagePreview
                this.saveImage = product.mainImgUploader
            }else{
                this.imgPreviewSrc = this.variant.image.url;
            }
            for(let i=0; i<this.optionsMain.category.length; i++){
                this.options.category.push(this.optionsMain.category[i])
                if(this.variant.category.id.toString() === this.optionsMain.category[i].id.toString())
                {
                    this.setOptions.category = i;
                }
            }
            this.allToFixed2();
            this.loading = false
        };
    },
    pushToAttribList(buffer)
    {
        for(let i=0; i<buffer.length; i++)
        {
            this.variant.attribs.list.push(buffer[i]);
        }
    },
    closeModal()
    {
        this.showModal = false;
    },
    async setProductStorageSegment()
    {
        const res = await axios.post(`${this.$store.state.apiLink}/storage/location/update`, {
            location: this.selected,
            currentProduct: this.$route.query.id,
            currentVariant: this.variant.id
        })
    },
    setCategory()
    {
        let newValue = this.options.category[this.setOptions.category];
        if(newValue === "")
        {
            this.variant.category.name = "";
            this.variant.category.ref = null;
            this.variant.category.id = "";
        }
        else
        {
            this.variant.category.name = newValue.name;
            this.variant.category.id = newValue.id.toString();
            this.variant.category.ref = db.collection("Categories").doc(newValue.id.toString());
        }
    },
    async getAdditionalOptions()
    {
        const categoryDocsResult = await db.collection("Categories").get();
        for(let i=0; i<categoryDocsResult.docs.length; i++)
        {
            this.options.category.push(categoryDocsResult.docs[i].data());
        }
    },
    dropFile(e)
    {
        if(e.dataTransfer.files.length>0)
        {
            if(e.dataTransfer.files[0].type.split('/')[0] === 'image')
            {
                if(e.dataTransfer.files[0].size <= this.fileLimitSize)
                {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(e.dataTransfer.files[0]);
                    fileReader.addEventListener("load", () =>
                    {
                        this.imgPreviewSrc = fileReader.result;
                    })
                }
                else
                {
                    this.$store.commit('setNotification',{
                        show: true,
                        head: "Zdjęcie waży powyżej 2MB",
                        subheader: `Plik jest zbyt ciężki.`,
                        success: false
                    }); 
                }
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Plik nie jest zdjęciem.",
                    subheader: `Plik powinien być zdjęciem.`,
                    success: false
                });   
            }
        }
        
    },
    triggerImgClick()
    {
        this.$refs.mainImgUploader.click();
    },
    clearImg()
    {
        this.photoToDeletePath = this.variant.image.ref;
        this.variant.image.ref = null;
        this.variant.image.url = "";
        this.imgPreviewSrc = '';
        this.$refs.mainImgUploader.value = "";
    },
    allToFixed2()
    {
        this.variant.pricesAndCosts.complaintCost = this.variant.pricesAndCosts.complaintCost.toFixed(2);
        this.variant.pricesAndCosts.productionCost = this.variant.pricesAndCosts.productionCost.toFixed(2);
        this.variant.pricesAndCosts.sellPrice = this.variant.pricesAndCosts.sellPrice.toFixed(2);
        this.variant.dimensions.height = this.variant.dimensions.height.toFixed(2);
        this.variant.dimensions.width = this.variant.dimensions.width.toFixed(2);
        this.variant.dimensions.length = this.variant.dimensions.length.toFixed(2);
        this.variant.weight.amount = this.variant.weight.amount.toFixed(2);
    },
    checkProductionCost()
    {
        if(this.variant.pricesAndCosts.productionCost.length === 0)
        {
            this.variant.pricesAndCosts.productionCost = 0;
        }
        if(this.variant.pricesAndCosts.productionCost < 0)
        {
            this.variant.pricesAndCosts.productionCost = 0;
        }
        else
        {
            this.variant.pricesAndCosts.productionCost = parseFloat(this.variant.pricesAndCosts.productionCost);
        }
        this.variant.pricesAndCosts.productionCost = this.variant.pricesAndCosts.productionCost.toFixed(2);
    },
    checkSellPrice()
    {
        if(this.variant.pricesAndCosts.sellPrice.length === 0)
        {
            this.variant.pricesAndCosts.sellPrice = 0;
        }
        if(this.variant.pricesAndCosts.sellPrice < 0)
        {
            this.variant.pricesAndCosts.sellPrice = 0;
        }
        else
        {
            this.variant.pricesAndCosts.sellPrice = parseFloat(this.variant.pricesAndCosts.sellPrice);
        }
        this.variant.pricesAndCosts.sellPrice = this.variant.pricesAndCosts.sellPrice.toFixed(2);
    },
    checkComplaintCost()
    {
        if(this.variant.pricesAndCosts.complaintCost.length === 0)
        {
            this.variant.pricesAndCosts.complaintCost = 0;
        }
        if(this.variant.pricesAndCosts.complaintCost < 0)
        {
            this.variant.pricesAndCosts.complaintCost = 0;
        }
        else
        {
            this.variant.pricesAndCosts.complaintCost = parseFloat(this.variant.pricesAndCosts.complaintCost);
        }
        this.variant.pricesAndCosts.complaintCost = this.variant.pricesAndCosts.complaintCost.toFixed(2);
    },
    checkHeight(type, index)
    {
        if(type === 'single')
        {
            if(this.variant.dimensions.height.length === 0)
            {
                this.variant.dimensions.height = 0;
            }
            if(this.variant.dimensions.height < 0)
            {
                this.variant.dimensions.height = 0;
            }
            else
            {
                this.variant.dimensions.height = parseFloat(this.variant.dimensions.height);
            }
            this.variant.dimensions.height = this.variant.dimensions.height.toFixed(2);
        }
        else if(type === 'multiple')
        {
            if(this.variant.parts[index].dimensions.height.length === 0)
            {
                this.variant.parts[index].dimensions.height = 0;
            }
            if(this.variant.parts[index].dimensions.height < 0)
            {
                this.variant.parts[index].dimensions.height = 0;
            }
            else
            {
                this.variant.parts[index].dimensions.height = parseFloat(this.variant.parts[index].dimensions.height);
            }
            this.variant.parts[index].dimensions.height = this.variant.parts[index].dimensions.height.toFixed(2);

        }
    },
    checkWidth(type, index)
    {
        if(type === 'single')
        {
            if(this.variant.dimensions.width.length === 0)
            {
                this.variant.dimensions.width = 0;
            }
            if(this.variant.dimensions.width < 0)
            {
                this.variant.dimensions.width = 0;
            }
            else
            {
                this.variant.dimensions.width = parseFloat(this.variant.dimensions.width);
            }
            this.variant.dimensions.width = this.variant.dimensions.width.toFixed(2);
        }
        else if(type === 'multiple')
        {
            if(this.variant.parts[index].dimensions.width.length === 0)
            {
                this.variant.parts[index].dimensions.width = 0;
            }
            if(this.variant.parts[index].dimensions.width < 0)
            {
                this.variant.parts[index].dimensions.width = 0;
            }
            else
            {
                this.variant.parts[index].dimensions.width = parseFloat(this.variant.parts[index].dimensions.width);
            }
            this.variant.parts[index].dimensions.width = this.variant.parts[index].dimensions.width.toFixed(2);
        }
    },
    checkLength(type, index)
    {
        if(type === 'single')
        {
            if(this.variant.dimensions.length.length === 0)
            {
                this.variant.dimensions.length = 0;
            }
            if(this.variant.dimensions.length < 0)
            {
                this.variant.dimensions.length = 0;
            }
            else
            {
                this.variant.dimensions.length = parseFloat(this.variant.dimensions.length);
            }
            this.variant.dimensions.length = this.variant.dimensions.length.toFixed(2);
        }
        else if(type === 'multiple')
        {
            if(this.variant.parts[index].dimensions.length.length === 0)
            {
                this.variant.parts[index].dimensions.length = 0;
            }
            if(this.variant.parts[index].dimensions.length < 0)
            {
                this.variant.parts[index].dimensions.length = 0;
            }
            else
            {
                this.variant.parts[index].dimensions.length = parseFloat(this.variant.parts[index].dimensions.length);
            }
            this.variant.parts[index].dimensions.length = this.variant.parts[index].dimensions.length.toFixed(2);
        }
    },
    checkWeight(type, index)
    {
        if(type === 'single')
        {
            if(this.variant.weight.amount.length === 0)
            {
                this.variant.weight.amount = 0;
            }
            if(this.variant.weight.amount < 0)
            {
                this.variant.weight.amount = 0;
            }
            else
            {
                this.variant.weight.amount = parseFloat(this.variant.weight.amount);
            }
            this.variant.weight.amount = this.variant.weight.amount.toFixed(2);
        }
        else if(type === 'multiple')
        {
            if(this.variant.parts[index].weight.amount.length === 0)
            {
                this.variant.parts[index].weight.amount = 0;
            }
            if(this.variant.parts[index].weight.amount < 0)
            {
                this.variant.parts[index].weight.amount = 0;
            }
            else
            {
                this.variant.parts[index].weight.amount = parseFloat(this.variant.parts[index].weight.amount);
            }
            this.variant.parts[index].weight.amount = this.variant.parts[index].weight.amount.toFixed(2);
        }
    },
    setMainImage()
    {
        if(this.$refs.mainImgUploader.files.length>0)
        {
            if(this.$refs.mainImgUploader.files[0].size <= this.fileLimitSize)
            {
                const fileReader = new FileReader();
                fileReader.readAsDataURL(this.$refs.mainImgUploader.files[0]);
                fileReader.addEventListener("load", () =>
                {
                    this.imgPreviewSrc = fileReader.result;
                })
            }
            else
            {
                this.$store.commit('setNotification',{
                    show: true,
                    head: "Zdjęcie waży powyżej 2MB",
                    subheader: `Plik jest zbyt ciężki.`,
                    success: false
                });                    
            }
        }
    },
    filenameGenerator(length, prefix)
    {
        let result           = `${prefix}_`;
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
        }
        return result;
    },
    sendBack()
    {
        this.$router.push("/dashboard/products")
    },
    resetErrors()
    {
        // Reset all errors
        for(let i=0; i<this.variant.amountOfPackages; i++)
        {
            this.errors.parts[i+1].error = false;
        }
    },
    partValidator()
    {
        let errorsAmount = 0;
        this.resetErrors();
        for(let i=0; i<this.variant.amountOfPackages; i++)
        {
            if(this.variant.parts[i+1].name.length === 0)
            {
                errorsAmount++;
                this.errors.parts[i+1].error = true;
            }
        }

        return errorsAmount;
    },
    async validateParts()
    {
        const errors = this.partValidator();
        if(errors > 0) return;
        this.changeStep(3);
    },
    changeStep(val)
    {
        switch(val)
        {
            case 1: 
                this.progress = 0;
                this.step = 1;
                break;
            case 2:
                if(this.validation1 === true)
                {
                    this.step = 2;
                    this.progress = 50;
                }
                break;
            case 3:
                this.step = 3;
                this.progress = 70;
                break;
            case 4:
                this.step = 4;
                this.progress = 100;
                setTimeout(() => {
                    this.updateVariant();
                }, 1200);
        }
    },
  },
    watch: {
        'variant.amountOfPackages'(changed) {
            if(changed)
            {
                this.errors.parts = {};
                this.variant.parts = {};
                for(let i=0; i<this.variant.amountOfPackages; i++)
                {
                    if(this.variant.parts[i+1] === undefined)
                    {
                        this.variant.parts[i+1] = {
                            name: '',
                            quantity: 0,
                            dimensions: {
                                unit: "cm",
                                height: 0,
                                width: 0,
                                length: 0
                            },
                            weight: {
                                unit: "kg",
                                amount: 0,
                            },
                        };
                    }
                    if(this.toggleInformation[i+1] === undefined)
                    {
                        this.toggleInformation[i+1] = {
                            open: false,
                        };
                    }
                    if(this.errors.parts[i+1] === undefined)
                    {
                        this.errors.parts[i+1] = {
                            error: false,
                        };
                    }
                }
                if(this.productCopy.parts != null)
                {
                    const parts = Object.entries(this.productCopy.parts);
                    for(let i=0; i<parts.length; i++)
                    {
                        let str = parts[i][1].name;
                        let result = '';

                        if(str.charAt(0) === '-')
                        {
                            result = str;
                        }
                        else
                        {
                            let num = str.lastIndexOf('-');
                            if(num > -1)
                            {
                                result = str.slice(0, num)
                            }
                            else
                            {
                                result = str;
                            }
                        }

                        if(this.variant.parts[i+1] != null)
                        {
                            this.variant.parts[i+1].name = result;
                            this.variant.parts[i+1].quantity = parts[i][1].quantity;
                            this.variant.parts[i+1].dimensions = {};
                            this.variant.parts[i+1].weight = {};
                            this.variant.parts[i+1].dimensions = {
                                unit: "cm",
                                height: parts[i][1].dimensions != null ? parts[i][1].dimensions.height : 0,
                                width: parts[i][1].dimensions != null ? parts[i][1].dimensions.width : 0,
                                length: parts[i][1].dimensions != null ? parts[i][1].dimensions.length : 0
                            };
                            this.variant.parts[i+1].weight = {
                                unit: "kg",
                                amount: parts[i][1].weight != null ? parts[i][1].weight.amount : 0,
                            };
                        }
                    };
                }
            }
        }
  },
  computed: {
    validation1()
    {
        if(this.variant.name.length>0)
        {
            return true;
        }
        else
        {
            return false;
        }
    }
  },
  mounted() {
    this.checkForProduct();
  },
  created() {
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
